import React, { useEffect, useState } from "react"
import Tag from "../UI/Tag/tag"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import LinkButton from "../UI/LinkButton/link-button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getYouTubeEmbedUrl } from "../../../utils/gatsby-helpers"
import useScrollListener from "../../../hooks/useScrollListener"

const Channels = ({channel_inperson_callcenter, channel_online, channel_all}) => {
    const scroll = useScrollListener()
    const [animationClassList, setAnimationClassList] = useState([null, null, null])
    const [video, setVideo] = useState(null)
    const [showVideoModal, setShowVideoModal] = useState(false)

    const openVideoModal = (video) => {
        setVideo(video)
        setShowVideoModal(true)
    }

    useEffect(() => {
        const animationOne = document.getElementById("animation-1")
        const animationTwo = document.getElementById("animation-2")
        const animationThree = document.getElementById("animation-3")
        const _classList = [null, null, null]

        if (scroll.y > animationOne.offsetTop - (document.body.offsetHeight * 0.75))
            _classList[0] = 'show'

        if (scroll.y > animationTwo.offsetTop - (document.body.offsetHeight * 0.75))
            _classList[1] = 'show'

        if (scroll.y > animationThree.offsetTop - (document.body.offsetHeight * 0.75))
            _classList[2] = 'show'

        if (animationClassList.some(i => i == null)) {
            setAnimationClassList(_classList)
        }
    }, [scroll.y, scroll.lastY])

    return (
        <>
            <div className="main__section main__section--50-overflow">
                <div className="container">
                    <div className="container__50">
                        <div className="tag-group ai-flex-start">
                            {channel_inperson_callcenter.tags.map((tag, index) => <Tag key={index} tag={tag} />)}
                        </div>
                        <h2>{channel_inperson_callcenter.title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={channel_inperson_callcenter.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <LinkButton button={channel_inperson_callcenter.button} className="button button--xl" />
                    </div>

                    <div className="container__overflow">
                        <div id="animation-1" className={`container__overflow__media ${animationClassList[0] ?? ''}`}>
                            <GatsbyImage
                                alt={channel_inperson_callcenter.video.video_preview.alternativeText}
                                image={getImage(channel_inperson_callcenter.video.video_preview.localFile)} />
                            <div onClick={() => openVideoModal(channel_inperson_callcenter.video)} className="button-icon">
                                <i className="icon-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main__section  main__section--50-overflow main__section--50-overflow-invert ">
                <div className="container">
                    <div className="container__50">
                        <div className="tag-group ai-flex-start">
                            {channel_online.tags.map((tag, index) => <Tag key={index} tag={tag} />)}
                        </div>
                        <h2>{channel_online.title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={channel_online.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <LinkButton button={channel_online.button} className="button button--xl" />
                    </div>

                    <div className="container__overflow">
                        <div id="animation-2" className={`container__overflow__media ${animationClassList[1] ?? ''}`}>
                            <GatsbyImage
                                alt={channel_online.video.video_preview.alternativeText}
                                image={getImage(channel_online.video.video_preview.localFile)} />
                            <div onClick={() => openVideoModal(channel_online.video)} className="button-icon">
                                <i className="icon-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main__section  main__section--50-overflow ">
                <div className="container">
                    <div className="container__50">
                        <div className="tag-group ai-flex-start">
                            {channel_all.tags.map((tag, index) => <Tag key={index} tag={tag} />)}
                        </div>
                        <h2>{channel_all.title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={channel_all.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <LinkButton button={channel_all.button} className="button button--xl" />
                    </div>

                    <div className="container__overflow">
                        <div id="animation-3" className={`container__overflow__media ${animationClassList[2] ?? ''}`}>
                            <GatsbyImage
                                alt={channel_all.video.video_preview.alternativeText}
                                image={getImage(channel_all.video.video_preview.localFile)} />
                            <div onClick={() => openVideoModal(channel_all.video)} className="button-icon">
                                <i className="icon-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showVideoModal &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={() => setShowVideoModal(false)}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                loading="lazy"
                                src={getYouTubeEmbedUrl(video.video_url)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="true"
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Channels
