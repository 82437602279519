import { graphql } from 'gatsby';
import React from 'react';
import NewLayout from '../components/new_layout';
import SEO from '../components/seo';
import { MainSection100, MainSection50 } from '../components/Layout/sections';
import { Container, Container50 } from '../components/Layout/containers';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Tag from '../components/v2023/UI/Tag/tag';
import LinkButton from '../components/v2023/UI/LinkButton/link-button';
import ImageGroup from '../components/v2024/image/image-group';
import { Tile, WhiteTileOpinion } from '../components/v2024/tile/tile';
import { mountAvatarObject } from '../utils/avatar-utils';
import YoutubeVideo from '../components/v2024/video/youtube-video';
import { Tabs } from '../components/v2024/tabs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReadyToTalk from '../components/v2023/ReadyToTalk/ready-to-talk';
import { Player } from '@lottiefiles/react-lottie-player';
import { DemoSteps } from '../components/v2024/demo-steps';
import Channels from '../components/v2023/CustomerHub/channels';


const customerHubV2Page = ({ data, pageContext }) => {
	console.log("🚀 ~ customerHubV2Page ~ pageContext:", pageContext)
	console.log("🚀 ~ customerHubV2Page ~ data:", data)

    const page = data.page
    console.log("🚀 ~ customerHubV2Page ~ page:", page)
    const opinionAvatar = mountAvatarObject(page.opinion.name, page.opinion.job_position)

    const tabs = [
        {
            title: page.tab_title_content_manager,
            content: (
                <div className="tabs__content-column">
                    <div className="tabs__content-column__50">
                        <h2>{page.tab_content_content_manager.title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.tab_content_content_manager.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                    <div className="tabs__content-column__50">
                        <GatsbyImage alt={page.tab_content_content_manager.image.alternativeText} image={getImage(page.tab_content_content_manager.image.localFile)} />
                    </div>
                </div>
            )
        },
        {
            title: page.tab_title_bpo,
            content: (
                <div className="tabs__content-column">
                    <div className="tabs__content-column__50">
                        <h2>{page.tab_content_bpo.title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.tab_content_bpo.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                    <div className="tabs__content-column__50">
                        <GatsbyImage alt={page.tab_content_bpo.image.alternativeText} image={getImage(page.tab_content_bpo.image.localFile)} />
                    </div>
                </div>
            )
        }
    ]
    const animation = page.animation_steps
    animation[2]['tag'] = page.last_animation_steps_tag
    animation[2]['button'] = page.last_animation_steps_button

	return <NewLayout pageContext={pageContext}>
            <SEO lang={pageContext.langKey} title={page.seo.title} description={page.seo.meta_description} translates={pageContext.translates} image={page.seo.image_2.localFile.publicURL} />
            <main className="main">
                <MainSection100 sectionColor="purple-dark" sectionType="color" hero frameGray>
                    <Container>
                        <div data-aos="fade-up">
                            {page.hero_tag.map((tag, index) => <Tag tag={tag} key={index} />)}
                        </div>
                        <div className="container__text" data-aos="fade-up">
                            <h1 className="merriweather">{page.hero_title}</h1>
                            <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            <div className="button__wrapper">
                                <LinkButton button={page.hero_button} className='button button--ghost button--xl' />
                            </div>
                        </div>
                    </Container>
                    <Container np animation="fade-up">
                        <DemoSteps demoSteps={page.animation_steps} category="customer-hub" >
                            <div className="hidden-sm">
                                <Player src={page.animation.localFile.publicURL} autoplay={true} loop={true} />
                            </div>
                            <div className="show-sm">
                                <Player src={page.animation_mobile.localFile.publicURL} autoplay={true} loop={true} />
                            </div>
                        </DemoSteps>
                    </Container>
                    <Container center animation="fade-up">
                        <div className='grid-lg-3-h'>
                            {page.hero_characteristics.map((characteristic, index) => <ImageGroup basicInfoWithImage={characteristic} key={index} animation={{'data-aos': 'zoom-in', 'data-aos-delay': 200 * index }} />)}
                        </div>
                    </Container>
                </MainSection100>
                <MainSection100 sectionColor="gray" sectionType="color" grayNograph>
                    <Container slim>
                        <WhiteTileOpinion logo={page.opinion.company_logo_2} opinion={page.opinion.message} avatar={opinionAvatar} />
                        <div class="grid-lg-2">
                            <Tile title={page.others.title} body={page.others.description} whiteSoft/>
                            <Tile title={page.tecalis.title} body={page.tecalis.description} tag={{name: page.tecalis.tag}} whiteNb/>
                        </div>
                    </Container>
                </MainSection100>
                <Channels 
                    channel_inperson_callcenter={page.frontal}
                    channel_online={page.portal}
                    channel_all={page.advanced_center}
                />
                <MainSection100>
                    <Container>
                        <Tabs pills small tabs={tabs} />
                    </Container>
                </MainSection100>
                <MainSection100>
                    <Container>
                        <div className="tag tag--status">
                            <i className="icon-rocket"></i>
                            {page.ad_hoc_tag.name}
                        </div>
                        <h2>
                            <ReactMarkdown children={page.ad_hoc_title} rehypePlugins={[rehypeRaw]} />
                        </h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.ad_hoc_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className='grid-lg-4'>
                            {page.ad_hoc_items.map((item, index) => <ImageGroup basicInfoWithImage={item} key={index} noTitle/>)}
                        </div>
                    </Container>
                </MainSection100>
                <MainSection100>
                    <Container>
                        <div className='grid-sm-4'>
                            {page.metrics.map((metric, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index };

                                return (
                                <div className='stat' key={index} {...animation}>
                                    <div className="title-xl">{metric.number}{metric.symbol}</div>
                                    <span>
                                        <ReactMarkdown children={metric.description} rehypePlugins={[rehypeRaw]} />
                                    </span>
                                </div>
                                );
                            })}
                        </div>
                    </Container>
                </MainSection100>
                <MainSection50 sectionType="color" sectionColor="gray" noFrame>
                    <Container>
                        <Container50>
                            <h2>{page.kiosks.title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.kiosks.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <LinkButton button={page.kiosks.button} className='button button--xl' />
                        </Container50>
                        <Container50>
                            <GatsbyImage alt={page.kiosks.image.alternativeText} image={getImage(page.kiosks.image.localFile)} />
                        </Container50>
                    </Container>
                </MainSection50>
                <MainSection100>
                    <Container>
                        <Tile title={page.add_ons.name} body={page.add_ons.description} image={page.add_ons.image} button={{text: page.add_ons.button, url: page.add_ons.url}} />
                    </Container>
                </MainSection100>
                <ReadyToTalk lang={pageContext.langKey} />
            </main>
        </NewLayout>
};

export default customerHubV2Page;

export const customerHubV2Query = graphql`
    query ($langKey: String) {
        page: strapi2025CustomerHubV22(locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            hero_tag {
                name
                type
            }
            hero_title
            hero_description
            hero_button {
                text
                url
                blank
            }
            animation {
                localFile {
                    publicURL
                }
            }
            animation_mobile {
                localFile {
                    publicURL
                }
            }
            animation_steps {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1136
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512,704,928,1200]
                            )
                        }
                    }
                }
            }
            last_animation_steps_tag {
                name
                type
            }
            last_animation_steps_button {
                text
                url
                blank
            }
            hero_characteristics {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            opinion {
                name
                job_position
                message
                company_logo_2{
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 280
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            others {
                title
                description
            }
            tecalis {
                title
                tag
                description
            }
            frontal {
                title
                description
                tags {
                    name
                    type
                }
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1118
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                    breakpoints: [512,704,928]
                                )
                            }
                        }
                    }
                }
            }
            portal {
                title
                description
                tags {
                    name
                    type
                }
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1118
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                    breakpoints: [512,704,928]
                                )
                            }
                        }
                    }
                }
            }
            advanced_center {
                title
                description
                tags {
                    name
                    type
                }
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1118
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                    breakpoints: [512,704,928]
                                )
                            }
                        }
                    }
                }
            }
            tab_title_content_manager
            tab_title_bpo
            tab_content_content_manager {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            tab_content_bpo {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            ad_hoc_title
            ad_hoc_description
            ad_hoc_tag {
                name
                type
            }
            ad_hoc_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 444
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [314]
                            )
                        }
                    }
                }
            }
            metrics {
                number
                symbol
                description
            }
            kiosks {
                title
                description
                button {
                    text
                    url
                    blank
                }
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 588
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            add_ons {
                name
                description
                button
                url
                blank
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 464
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`